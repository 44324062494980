<!--
  This example requires Tailwind CSS v2.0+ 
  
  <template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>


  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <div class="bg-black">
    <header>
      <Popover class="relative bg-black">
        <div
          class="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-5 lg:px-8">
          <div class="flex justify-start">
            <a href="/">
              <span class="sr-only">Workflow</span>
              <img
                class="h-10 w-auto sm:h-20"
                src="./assets/logo_sans_fond.png"
                alt="" />
            </a>
          </div>
          <div class="-mr-2 -my-2 md:hidden">
            <PopoverButton
              class="bg-black rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
              <span class="sr-only">Ouvrir le menu</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>
          <PopoverGroup as="nav" class="hidden md:flex space-x-8">
            <!-- <a
              href="/actus"
              class="text-base font-medium text-yellow-400 hover:text-white">
              Actualités
            </a> -->
            <a
              href="/infos_sport"
              class="text-base font-medium text-yellow-400 hover:text-white">
              Infos sportives
            </a>
            <Popover class="relative" v-slot="{ open }">
              <PopoverButton
                :class="[
                  open ? 'text-white' : 'text-yellow-400',
                  'group bg-black rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500',
                ]">
                <span>Le Club</span>
                <ChevronDownIcon
                  :class="[
                    open ? 'text-white' : 'text-yellow-400',
                    'ml-2 h-5 w-5 group-hover:text-white',
                  ]"
                  aria-hidden="true" />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel
                  class="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                  <div
                    class="rounded-lg shadow-lg ring-1 ring-yellow-400 ring-opacity-5 overflow-hidden">
                    <div
                      class="relative grid gap-6 bg-black px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                      <a
                        v-for="item in club"
                        :key="item.name"
                        :href="item.href"
                        class="-m-3 p-3 flex items-start rounded-lg border-2 border-black hover:border-yellow-50">
                        <div
                          class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-yellow-300 to-yellow-400 text-white sm:h-12 sm:w-12">
                          <component
                            :is="item.icon"
                            class="h-6 w-6"
                            aria-hidden="true" />
                        </div>
                        <div class="ml-4">
                          <p class="text-base font-medium text-white">
                            {{ item.name }}
                          </p>
                          <p class="mt-1 text-sm text-yellow-200">
                            {{ item.description }}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>

            <a
              href="/teams"
              class="text-base font-medium text-yellow-400 hover:text-white"
              >Les Equipes</a
            >
            <a
              href="/club/boutique"
              class="text-base font-medium text-yellow-400 hover:text-white">
              La Boutique
            </a>
            <Popover class="relative" v-slot="{ open }">
              <PopoverButton
                :class="[
                  open ? 'text-white' : 'text-yellow-400',
                  'group bg-black rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500',
                ]">
                <span>Infos Pratiques</span>
                <ChevronDownIcon
                  :class="[
                    open ? 'text-white' : 'text-yellow-400',
                    'ml-2 h-5 w-5 group-hover:text-white',
                  ]"
                  aria-hidden="true" />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel
                  class="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                  <div
                    class="rounded-lg shadow-lg ring-1 ring-yellow-400 ring-opacity-5 overflow-hidden">
                    <div
                      class="relative grid gap-6 bg-black px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                      <a
                        v-for="item in infosPratiques"
                        :key="item.name"
                        :href="item.href"
                        class="-m-3 p-3 flex items-start rounded-lg border-2 border-black hover:border-yellow-50">
                        <div
                          class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-yellow-300 to-yellow-400 text-white sm:h-12 sm:w-12">
                          <component
                            :is="item.icon"
                            class="h-6 w-6"
                            aria-hidden="true" />
                        </div>
                        <div class="ml-4">
                          <p class="text-base font-medium text-white">
                            {{ item.name }}
                          </p>
                          <p class="mt-1 text-sm text-yellow-200">
                            {{ item.description }}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>
            <a
              href="/photos"
              class="text-base font-medium text-yellow-400 hover:text-white"
              >Albums photo</a
            >
            <a
              href="/contact"
              class="text-base font-medium text-yellow-400 hover:text-white">
              Contact
            </a>
          </PopoverGroup>
          <div class="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
            <a
              href="/join"
              class="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-yellow-300 to-yellow-400 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-yellow-700 hover:to-yellow-700">
              VENIR JOUER
            </a>
          </div>
        </div>

        <transition
          enter-active-class="duration-200 ease-out"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="duration-100 ease-in"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95">
          <PopoverPanel
            focus
            class="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div
              class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black divide-y-2 divide-gray-50">
              <div class="pt-5 pb-6 px-5">
                <div class="flex items-center justify-between">
                  <div>
                    <img
                      class="h-8 w-auto"
                      src="assets/logo_sans_fond.png"
                      alt="Workflow" />
                  </div>
                  <div class="-mr-2">
                    <PopoverButton
                      class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
                      <span class="sr-only">Close menu</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
                <div class="mt-6 grid grid-cols-2 gap-4">
                  <!-- <a
                    href="/actus"
                    class="text-base font-medium text-yellow-400 hover:text-white">
                    Actualités
                  </a> -->
                  <a
                    href="/infos_sport"
                    class="text-base font-medium text-yellow-400 hover:text-white">
                    Infos sportives
                  </a>
                  <a
                    href="/club"
                    class="text-base font-medium text-yellow-400 hover:text-white"
                    >Le club</a
                  >
                  <a
                    href="/infos"
                    class="text-base font-medium text-yellow-400 hover:text-white"
                    >Infos pratiques</a
                  >
                  <a
                    href="/teams"
                    class="text-base font-medium text-yellow-400 hover:text-white"
                    >Les Equipes</a
                  >
                  <a
                    href="/photos"
                    class="text-base font-medium text-yellow-400 hover:text-white"
                    >Albums photo</a
                  >
                  <a
                    href="/club/boutique"
                    class="text-base font-medium text-yellow-400 hover:text-white">
                    La boutique
                  </a>
                  <a
                    href="/sponsors"
                    class="text-base font-medium text-yellow-400 hover:text-white">
                    Les partenaires
                  </a>
                </div>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </header>

    <router-view :is="activeComponent" />

    <footer class="bg-black" aria-labelledby="footer-heading">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="max-w-7xl mx-auto pt-2 pb-8 px-4 sm:px-6 lg:pt-4 lg:px-8">
        <div
          class="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
          <div class="flex space-x-6 md:order-2">
            <a
              v-for="item in footerNavigation.social"
              :key="item.name"
              :href="item.href"
              target="blank"
              class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">{{ item.name }}</span>
              <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
            </a>
          </div>
          <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; 2022 SEHC. Tous droits réservés.
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { defineComponent, h } from "vue";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import {
  MenuIcon,
  XIcon,
  LibraryIcon,
  LocationMarkerIcon,
  BookOpenIcon,
  UserGroupIcon,
  BriefcaseIcon,
  SupportIcon,
  FolderOpenIcon,
} from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";

const club = [
  {
    name: "Son histoire",
    description:
      "Le 18 juillet 2000, le Sud Estuaire Handball Club (SEHC) est né !",
    href: "/club/history",
    icon: BookOpenIcon,
  },
  {
    name: "Conseil d'administration - Bureau",
    description: "Découvrez tous les membres",
    href: "/club/ca",
    icon: UserGroupIcon,
  },
  {
    name: "Les bénévoles",
    description: "Rencontrez quelques bénévoles",
    href: "/club/benevole",
    icon: SupportIcon,
  },
  {
    name: "La boutique",
    description: "Achetez vos équipements et goodies aux couleurs du club",
    href: "/club/boutique",
    icon: LibraryIcon,
  },
  {
    name: "Les Partenaires",
    description: "Ils nous soutienneent toute l'année et nous les remercions !",
    href: "/club/sponsors",
    icon: BriefcaseIcon,
  },
];

const infosPratiques = [
  {
    name: "Les Salles",
    description: "La carte des salles avec le manuel de la table de marque",
    href: "/infos/salles",
    icon: LocationMarkerIcon,
  },
  {
    name: "Horaires des entrainements",
    description: "Detrouvez les horaires des entrainemnets",
    href: "/infos/entrainements",
    icon: UserGroupIcon,
  },
  {
    name: "Dossier de licence",
    description: "Tous les documents pour votre licence",
    href: "/join",
    icon: FolderOpenIcon,
  },
];

const footerNavigation = {
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/profile.php?id=100086921265881/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Instagram",
      href: "https://instagram.com/sudestuairehandball?igshid=YmMyMTA2M2Y=",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
  ],
};
</script>
<style scoped>
header::after {
  position: absolute;
  content: "";
  left: 0;
  top: 250px;
  width: 50%;
  height: 90px;
  background-color: #fbbf24;
  z-index: 0;
  -webkit-transform: skew(0, 25deg);
  -ms-transform: skew(0, 25deg);
  transform: skew(0, 25deg);
}

main::before {
  position: absolute;
  content: "";
  right: 0;
  top: 250px;
  width: 50%;
  height: 90px;
  background-color: #fbbf24;
  z-index: 0;
  -webkit-transform: skew(0, -25deg);
  -ms-transform: skew(0, -25deg);
  transform: skew(0, -25deg);
}
</style>
