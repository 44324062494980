import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/HomeView.vue";
import join from "../views/JoinView.vue";
import club from "../views/club/indexView.vue";
import History from "../views/club/HistoryView.vue";
import CA from "../views/club/CaView.vue";
import boutique from "../views/club/BoutiqueView.vue";
import Actus from "../views/actus/IndexView.vue";
import Actu from "../views/actus/:id/IndexView.vue";
import Benevoles from "../views/club/benevole/BenevoleView.vue";
import Benevole from "../views/club//benevole/:slug/IndexView.vue";
import teams from "../views/team/indexView.vue";
import soloTeams from "../views/team/:slug/indexView.vue";
import TV from "../views/TvView.vue";
import infos from "../views/infos/indexView.vue";
import salle from "../views/infos/salleView.vue";
import entrainements from "../views/infos/planningEntrainementView.vue";
import Sponsors from "../views/club/SponsorsView.vue";
import Contact from "../views/ContactView.vue";
import Photos from "../views/photoAlbumView.vue";
import InfosSport from "../views/InfosSportView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/join",
    name: "join",
    component: join,
  },
  {
    path: "/club",
    name: "club",
    component: club,
  },
  {
    path: "/club/history",
    name: "History",
    component: History,
  },
  {
    path: "/club/ca",
    name: "CA",
    component: CA,
  },
  {
    path: "/club/benevole",
    name: "benevoles",
    component: Benevoles,
  },
  {
    path: "/club/benevole/:slug",
    name: "benevole",
    component: Benevole,
  },
  {
    path: "/club/boutique",
    name: "boutique",
    component: boutique,
  },
  {
    path: "/actus",
    name: "actus",
    component: Actus,
  },
  {
    path: "/actus/:id",
    name: "actu",
    component: Actu,
  },
  {
    path: "/infos",
    name: "infos",
    component: infos,
  },
  {
    path: "/infos/salles",
    name: "salle",
    component: salle,
  },
  {
    path: "/infos/entrainements",
    name: "entrainements",
    component: entrainements,
  },
  {
    path: "/teams",
    name: "teams",
    component: teams,
  },
  { path: '/teams/:slug', name: "solo_teams",component: soloTeams },
  {
    path: "/tv",
    name: "TV",
    component: TV,
  },
  {
    path: "/club/sponsors",
    name: "sponsors",
    component: Sponsors,
  },
  {
    path: "/photos",
    name: "photos",
    component: Photos,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/infos_sport",
    name: "InfosSport",
    component: InfosSport,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
